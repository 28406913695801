@charset "utf-8";

$saleColor: #0068aa;
$purchaseColor: #e34b37;
$investmentColor: #ffa000;

// ---------------------------
// MV
// ---------------------------
.mv {
  min-width: 1000px;
  height: 440px;
  padding-top: 80px;
  background: url(../images/mv.jpg) center center / cover;
}

.catch-copy {
  width: 15em;
  font-size: 40px;

  &__line {
    display: block;
    margin-bottom: 8px;
    padding: 7px 10px 10px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.05em;
    color: #fff;
    background: #333;
  }
}

.mv-txt {
  margin-top: 14px;
  font-size: 20px;
  line-height: 1.71;
  letter-spacing: -0.02em;
  color: #fff;
}

// ---------------------------
// お知らせ
// ---------------------------
.sec-news {
  margin-top: -64px;
  padding: 53px 0 56px;
  background: #fff;
}

// ---------------------------
// 案件情報
// ---------------------------
.sec-cases {
  padding: 95px 0 120px;
}

// ---------------------------
// 検索フォーム
// ---------------------------
.search-container {
  margin-top: 50px;
  background: #fff;
}

// タブ
.search-tab-container {
  display: flex;
}
.search-tab {
  width: 50%;
  cursor: pointer;
  background: #f1f1f1;

  &__link {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }

  &__txt {
    display: flex;
    height: 64px;
    line-height: 1;
    justify-content: center;
    align-items: center;
  }

  &__kind {
    font-size: 24px;
  }
}
.tab-sale .search-tab__link {
  &::before {
    background: #fff url(../images/icon_sale.svg) center center / 27px 25px no-repeat;
  }
}
.tab-investment .search-tab__link {
  &::before {
    background: #fff url(../images/icon_investment.svg) center center / 31px 20px no-repeat;
  }
}

// JS関連
.js-selected-tab {
  position: relative;

  & .search-tab__txt {
    color: #fff;
  }

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-width: 9px 10px 0 10px;
    border-style: solid;
    border-color: $saleColor transparent transparent transparent;
  }
}
.tab-sale.js-selected-tab {
  background: $saleColor;
  &::before {
    border-color: $saleColor transparent transparent transparent;
  }
}
// .tab-purchase.js-selected-tab {
//   background: $purchaseColor;
//   &::before {
//     border-color: $purchaseColor transparent transparent transparent;
//   }
.tab-investment.js-selected-tab {
  background: $investmentColor;
  &::before {
    border-color: $investmentColor transparent transparent transparent;
  }
}

// フォーム
.search-input-container {
  padding: 32px 40px;
}
.js-selected-tab-sale {
  .investment-item {
    display: none;
  }
}
.js-selected-tab-investment {
  .sale-item {
    display: none;
  }
}
.search-keyword,
.search-country,
.search-industry,
.search-sale-amount {
  display: flex;
  margin-top: 24px;
  align-items: center;
}
.search-form {
  &__lbl{
    width: 150px;
    flex-grow: 0;
  }

  .search-placeholder {
    height: 30px;
    cursor:pointer;
    color: #dfdfdf;
  }

  &__input-box,
  &__selecteditem-box {
    width: 100%;
    padding: 4px 20px 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    flex-grow: 1;
  }

  &__selecteditem-box {
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      display: block;
      width: 16px;
      height: 2px;
      transform: translateY(-50%);
      background: #333;
    }

    &::after {
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &__enable-items {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: -1px;
    display: none;
    overflow: auto;
    width: calc(100% + 2px);
    height: 10em;
    line-height: 2;
    transform: translateY(100%);
    border: 1px solid #dfdfdf;
    background: #fff;

  }
  &__enable-row {
    padding: 0 10px;
    cursor: pointer;
    &:hover {
      color: #fff;
      background: #25925688;
    }
  }

  &__hide-select {
    position: absolute;
    top: 0;
    right: 0px;
    opacity: 0;
  }

  &__btns {
    display: flex;
    width: 80%;
    margin: 48px auto 0;
    justify-content: center;
    align-items: center;
  }

  &__remove-btn {
    width: 296px;
    height: 48px;
    margin-right: 16px;
    cursor: pointer;

    & a {
      display: flex;
      width: 100%;
      height: 100%;
      color: $accentColor;
      border: solid 2px $accentColor;
      background: #fff;
      justify-content: center;
      align-items: center;
    }
  }

  &__submit-btn {
    width: 296px;
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
    color: #fefefe;
    border: none;
    background: $accentColor;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

// モーダル
.country-modal-bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}
.country-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 960px;
  height: 80%;
  max-height: 828px;
  padding: 32px 41px 0;
  transform: translate(-50%, -50%);
  background: #fff;

  &__close-btn {
    content: "" ;
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: url(../images/icon_close.svg) center center / 33px 33px no-repeat;

  }

  &__container {
    overflow: auto;
    height: calc(100% - 160px);
    margin-top: 12px;
  }
}
.check-region {
  padding: 6px 27px;
  background-color: #e9f4ee;
}
.check-country {
  display: flex;
  margin-bottom: 10px;
  padding: 6px 27px;
  flex-wrap: wrap;
}
.check-country li {
  position: relative;
  width: 25%;
  margin-bottom: 5px;
}
input[type=checkbox] {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}
input[type=checkbox] + label {
  position: relative;
  padding-left: 30px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    border: 1px solid #dfdfdf;
    background: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    opacity: 0;
    background: $accentColor url(../images/icon_check.svg) center center / 14px 10px no-repeat;
  }
}
input[type=checkbox]:checked + label {
  &::after {
    opacity: 1;
  }
}
.country-btns {
  display: flex;
  width: 70%;
  margin: 30px auto 0;
  justify-content: space-between;

  .clear-btn {
    width: 296px;
    height: 48px;
    cursor: pointer;

    & a {
      display: flex;
      width: 100%;
      height: 100%;
      color: $accentColor;
      border: solid 2px $accentColor;
      background: #fff;
      justify-content: center;
      align-items: center;
    }
  }
  .green-btn {
    margin: 0;
  }
}
// ---------------------------
// JSで操作するクラス
// ---------------------------
.js-selected-list {
  color: #fff;
  background: $accentColor;
}
.js-selected-item {
  display: inline-block;
  height: 30px;
  margin: 3px;
  padding: 0 5px;
  vertical-align: middle;
  border-radius: 5px;
  background: #dfdfdf;

  .js-remove-item {
    position: relative;
    top: -2px;
    display: inline-block;
    width: 20px;
    height:100%;
    font-size: 16px;
    cursor: pointer;
    vertical-align: middle;
    background: transparent;

    &::before,
    &::after {
      content: "";
      position: absolute;
      position: absolute;
      top: 50%;
      display: block;
      width: 70%;
      height: 1px;
      transform: rotate(45deg);
      background: #333;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.js-fixed {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
}

// ---------------------------
// 検索結果（案件毎の情報）
// ---------------------------
.result {
  margin-top: 48px;
}
.result-list {
  margin-bottom: 56px;
}

.result-item-container {
  position: relative;
  overflow: hidden;
  margin-top: 42px;
  border-top: 4px solid #0068aa;
  background: #fff;
}

.result-item {
  padding: 32px 32px 36px;
  border: 1px solid #dfdfdf;

  &::after {
    background: #0068aa;
  }

  &__no {
    &--category {
      display: inline-block;
      margin-right: 8px;
      padding: 3px 12px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      color: #0068aa;
      border: 2px solid #0068aa;
      border-radius: 20px;
    }
  }

  &__name {
    margin-top: 4px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }

  &__info {
    display: flex;
    margin-top: 13px;

    &--item {
      width: 32%;
      margin-right: 2%;

      &:last-child {
        margin-right: 0;
      }
    }

    &--box {
      width: 100%;
      border: 1px solid #e5f0f6;
    }

    &--ttl {
      padding: 9px 0;
      font-size: 16px;
      line-height: 1;
      text-align: center;
      background: #e5f0f6;
    }

    &--value {
      padding: 21px 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      color: #0068aa;
    }
  }

  &__others {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;

    &--ttl {
      width: 10%;
      font-size: 16px;
      font-weight: bold;
      line-height: 2;
    }

    &--value {
      width: 90%;
      font-size: 16px;
      line-height: 2;
    }
  }

  &__contact {
    width: 320px;
    margin-top: 32px;
  }

  &__note {
    margin-top: 16px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    color: #b7b7b7;
  }
}

.new-case {
  &::after {
    content: "NEW";
    position: absolute;
    top: 12px;
    left: -2px;
    display: block;
    width: 80px;
    height: 40px;
    padding-top: 21px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    text-align: center;
    color: #fefefe;
  }
}

.result-sale {
  border-color: $saleColor;
  &::after {
    background: $saleColor;
  }

  .result-item {
    &::after {
      background: $saleColor;
    }

    &__no {
      &--category {
        color: $saleColor;
        border-color: $saleColor;
      }
    }

    &__info {
      &--box {
        border: 1px solid #e5f0f6;
      }

      &--ttl {
        background: #e5f0f6;
      }

      &--value {
        color: $saleColor;
      }
    }
  }
}

.result-purchase {
  border-color: $purchaseColor;
  &::after {
    background: $purchaseColor;
  }

  .result-item  {
    &::after {
      background: $purchaseColor;
    }

    &__no {
      &--category {
        color: $purchaseColor;
        border-color: $purchaseColor;
      }
    }

    &__info {
      &--box {
        border: 1px solid #fcedeb;
      }

      &--ttl {
        background: #fcedeb;
      }

      &--value {
        color: $purchaseColor;
      }
    }

    &__others {
      &--ttl {
        width: 19%;
      }
      &--value {
        width: 81%;
      }
    }
  }
}

.result-investment {
  border-color: $investmentColor;
  &::after {
    background: $investmentColor;
  }
  .result-item {

    &::after {
      background: $investmentColor;
    }

    &__no {
      &--category {
        color: $investmentColor;
        border-color: $investmentColor;
      }
    }

    &__info {
      &--box {
        border: 1px solid #fff5e5;
      }

      &--ttl {
        background: #fff5e5;
      }

      &--value {
        color: $investmentColor;
      }
    }
  }
}

.all-btn {
  margin-top: 80px;
}
