@charset "utf-8";

// ---------------------------
// お知らせ記事ページ
// ---------------------------
.news-container {
  width: 800px;
  margin: 0 auto;
  padding: 77px 0 120px;
}

.news-article {
  &__date {
    font-size: 15px;
    line-height: 1;
    color: #bfbfbf;
  }

  &__ttl {
    margin-top: 2px;
    font-size: 24px;
    font-weight: 500;
  }

  &__pic {
    width: 100%;
    margin-top: 32px;
  }

  &__img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  &__txt {
    margin-top: 30px;
    line-height: 2;

    &--pdf {
      text-decoration: underline;
      color: $accentColor;
    }
  }
}
.news-article__txt--pdf[href$=".pdf"]::after {
  content: "";
  position: relative;
  bottom: -2px;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 3px;
  background: url(../images/icon_pdf.svg) center center / contain no-repeat;
}

// 画面下部のボタン領域
.news-page-btns {
  display: flex;
  width: 100%;
  margin-top: 74px;
  justify-content: space-between;
  align-items: center;
}
.pre-news-page__link,
.next-news-page__link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: $accentColor;
}
.pre-news-page__link::before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  margin-right: 15px;
  margin-left: 3px;
  transform: rotate(45deg);
  border-bottom: 2px solid $accentColor;
  border-left: 2px solid $accentColor;
}
.next-news-page__link::after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  margin-right: 3px;
  margin-left: 15px;
  transform: rotate(-135deg);
  border-bottom: 2px solid $accentColor;
  border-left: 2px solid $accentColor;
}

p.pre-news-page__link,
p.next-news-page__link {
  color: #bfbfbf;;
}
p.pre-news-page__link::before,
p.next-news-page__link::after {
  border-bottom: 2px solid #bfbfbf;
  border-left: 2px solid #bfbfbf;
}
