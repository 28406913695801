@charset "utf-8";

.clearfix {
  @include clearfix();
}

.container {
  // @include cwLayout();
  width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}
