@charset "utf-8";

.footer {
  padding: 20px 0 24px;
  background: #333;

  &__txt {
    line-height: 1;
    text-align: center;
  }

  &__copy {
    font-size: 10px;
    color: #fff;
  }
}
