@charset "utf-8";

html,
body {
  -webkit-text-size-adjust: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $baseFont;
  font-size: 16px;
  line-height: 1.8;
  letter-spacing: normal;
  word-wrap: break-word;
  color: $baseColor;
  background-color: $bgColor;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

a {
  transition: 0.2s;
  text-decoration: none;
  color: $linkColor;
  outline: none;

  &:hover {
    opacity: 0.7;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
