@charset "utf-8";

.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: #fff;

  .container {
    display: flex;
    height: 100%;
    margin: 0 auto;
    align-items: center;
  }

  &__ttl {
    width: 179px;
  }

  // &__solution {
  //   margin-right: 0;
  //   margin-left: auto;

  //   &--link {
  //     padding: 5px;
  //     font-size: 15px;
  //   }
  // }

  &__subscription,
  &__contact {
    width: 160px;
    height: 100%;
    margin-right: 0;
    margin-left: 10px;

    &--link {
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      background: $accentColor;
      justify-content: center;
      align-items: center;
    }
  }

  &__subscription {
    margin-right: 0;
    margin-left: auto;
  }
}
