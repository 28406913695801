@charset "utf-8";

// ---------------------------
// メイン領域
// ---------------------------
.main {
  padding-top: 70px;  // ヘッダーの高さ分
}

.bg-white {
  background: #fff;
}

// ---------------------------
// セクションタイトルと概要
// ---------------------------
.sec-ttl {
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  text-align: center;

  &__en {
    display: block;
    margin-top: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: $accentColor;
  }
}
.sec-txt {
  margin-top: 30px;
  text-align: center;
}

// ---------------------------
// お知らせ一覧
// ---------------------------
.news-list {
  margin: 38px;

  &__item {
    position: relative;
    border-bottom: 1px solid #dfdfdf;
    &:first-child {
      border-top: 1px solid #dfdfdf;
    }
  }

  .news-detail {
    display: flex;
    align-items: center;
    padding: 22px 0;

    &__day {
      width: 214px;
      padding-left: 24px;
      font-size: 15px;
      color: #bfbfbf;
    }
    &__txt {
      &--link {
        display: block;
        width: 100%;
        font-size: 16px;
        color: #333;
      }
    }
  }
}
.new-news {
  &::after {
    content: "NEW";
    position: absolute;
    top: 50%;
    left: 144px;
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    transform: translateY(-50%);
    color: #fff;
    background: $accentColor;
  }
}

// ---------------------------
// ページ番号ボタン
// ---------------------------
.page-btns {
  display: flex;
  justify-content: center;
}
.page-no {
  width: 40px;
  height: 40px;

  &:last-child &__link {
    border-right: 1px solid #dfdfdf;
  }

  &__link {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    color: $accentColor;
    border: 1px solid #dfdfdf;
    border-right: none;
    background: #fff;
    justify-content: center;
    align-items: center;
  }
}

.first-page .page-no__link::before,
.first-page .page-no__link::after,
.pre-page .page-no__link::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 17px;
  display: block;
  width: 9px;
  height: 9px;
  transform: translateY(-50%) rotate(45deg);
  border-bottom: 2px solid $accentColor;
  border-left: 2px solid $accentColor;
}
.last-page .page-no__link::before,
.last-page .page-no__link::after,
.next-page .page-no__link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 17px;
  display: block;
  width: 9px;
  height: 9px;
  margin: 0;
  transform: translateY(-50%) rotate(-45deg);
  border-right: 2px solid $accentColor;
  border-bottom: 2px solid $accentColor;
}
.first-page .page-no__link::before {
  left: 13px;
}
.first-page .page-no__link::after {
  left: 21px;
}
.last-page .page-no__link::before {
  right: 13px;
}
.last-page .page-no__link::after {
  right: 21px;
}

span.page-no__link {
  color: #fff;
  background: $accentColor;
}
.pre-page span.page-no__link{
  background: #fff;
  &::before {
    border-bottom: 2px solid #b7b7b7;
    border-left: 2px solid #b7b7b7;
  }
}
.next-page span.page-no__link {
  background: #fff;
  &::before {
    border-right: 2px solid #b7b7b7;
    border-bottom: 2px solid #b7b7b7;
  }
}

// ---------------------------
// 緑のリンクボタン
// ---------------------------
.green-btn {
  width: 296px;
  height: 48px;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;

  & a {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-weight: 500;
    transition: 0.3s;
    color: #fefefe;
    background: $accentColor;
    justify-content: center;
    align-items: center;
  }
}

// ---------------------------
// パンくずリスト
// ---------------------------
.breadcrumb-container {
  background: $bgColor;
}
.breadcrumb {
  display: flex;
  height: 36px;
  list-style-type: none;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    &::after {
      content: '>';
      padding: 0 4px;
      color: #555;
    }
    &:last-child::after {
      display: none;
    }
  }

  &__link {
    font-size: 12px;
  }
}
a.breadcrumb__link {
  display: block;
  color: $accentColor;
}
