@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
  max-width: 100%; }

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	you'll have to restore the bullets within content,
	which is fine because they're probably customized anyway */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

html,
body {
  -webkit-text-size-adjust: 100%; }

html {
  font-size: 62.5%; }

body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 16px;
  line-height: 1.8;
  letter-spacing: normal;
  word-wrap: break-word;
  color: #333;
  background-color: #e9f4ee;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased; }

a {
  transition: 0.2s;
  text-decoration: none;
  color: #333;
  outline: none; }
  a:hover {
    opacity: 0.7; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.container {
  width: 1000px;
  margin: 0 auto;
  padding: 0 20px; }

.main {
  padding-top: 70px; }

.bg-white {
  background: #fff; }

.sec-ttl {
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  text-align: center; }
  .sec-ttl__en {
    display: block;
    margin-top: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #259256; }

.sec-txt {
  margin-top: 30px;
  text-align: center; }

.news-list {
  margin: 38px; }
  .news-list__item {
    position: relative;
    border-bottom: 1px solid #dfdfdf; }
    .news-list__item:first-child {
      border-top: 1px solid #dfdfdf; }
  .news-list .news-detail {
    display: flex;
    align-items: center;
    padding: 22px 0; }
    .news-list .news-detail__day {
      width: 214px;
      padding-left: 24px;
      font-size: 15px;
      color: #bfbfbf; }
    .news-list .news-detail__txt--link {
      display: block;
      width: 100%;
      font-size: 16px;
      color: #333; }

.new-news::after {
  content: "NEW";
  position: absolute;
  top: 50%;
  left: 144px;
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  transform: translateY(-50%);
  color: #fff;
  background: #259256; }

.page-btns {
  display: flex;
  justify-content: center; }

.page-no {
  width: 40px;
  height: 40px; }
  .page-no:last-child .page-no__link {
    border-right: 1px solid #dfdfdf; }
  .page-no__link {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #259256;
    border: 1px solid #dfdfdf;
    border-right: none;
    background: #fff;
    justify-content: center;
    align-items: center; }

.first-page .page-no__link::before,
.first-page .page-no__link::after,
.pre-page .page-no__link::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 17px;
  display: block;
  width: 9px;
  height: 9px;
  transform: translateY(-50%) rotate(45deg);
  border-bottom: 2px solid #259256;
  border-left: 2px solid #259256; }

.last-page .page-no__link::before,
.last-page .page-no__link::after,
.next-page .page-no__link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 17px;
  display: block;
  width: 9px;
  height: 9px;
  margin: 0;
  transform: translateY(-50%) rotate(-45deg);
  border-right: 2px solid #259256;
  border-bottom: 2px solid #259256; }

.first-page .page-no__link::before {
  left: 13px; }

.first-page .page-no__link::after {
  left: 21px; }

.last-page .page-no__link::before {
  right: 13px; }

.last-page .page-no__link::after {
  right: 21px; }

span.page-no__link {
  color: #fff;
  background: #259256; }

.pre-page span.page-no__link {
  background: #fff; }
  .pre-page span.page-no__link::before {
    border-bottom: 2px solid #b7b7b7;
    border-left: 2px solid #b7b7b7; }

.next-page span.page-no__link {
  background: #fff; }
  .next-page span.page-no__link::before {
    border-right: 2px solid #b7b7b7;
    border-bottom: 2px solid #b7b7b7; }

.green-btn {
  width: 296px;
  height: 48px;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer; }
  .green-btn a {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-weight: 500;
    transition: 0.3s;
    color: #fefefe;
    background: #259256;
    justify-content: center;
    align-items: center; }

.breadcrumb-container {
  background: #e9f4ee; }

.breadcrumb {
  display: flex;
  height: 36px;
  list-style-type: none;
  align-items: center; }
  .breadcrumb__item {
    display: flex;
    align-items: center; }
    .breadcrumb__item::after {
      content: '>';
      padding: 0 4px;
      color: #555; }
    .breadcrumb__item:last-child::after {
      display: none; }
  .breadcrumb__link {
    font-size: 12px; }

a.breadcrumb__link {
  display: block;
  color: #259256; }

.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: #fff; }
  .header .container {
    display: flex;
    height: 100%;
    margin: 0 auto;
    align-items: center; }
  .header__ttl {
    width: 179px; }
  .header__subscription, .header__contact {
    width: 160px;
    height: 100%;
    margin-right: 0;
    margin-left: 10px; }
    .header__subscription--link, .header__contact--link {
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      background: #259256;
      justify-content: center;
      align-items: center; }
  .header__subscription {
    margin-right: 0;
    margin-left: auto; }

.footer {
  padding: 20px 0 24px;
  background: #333; }
  .footer__txt {
    line-height: 1;
    text-align: center; }
  .footer__copy {
    font-size: 10px;
    color: #fff; }

.mv {
  min-width: 1000px;
  height: 440px;
  padding-top: 80px;
  background: url(../images/mv.jpg) center center/cover; }

.catch-copy {
  width: 15em;
  font-size: 40px; }
  .catch-copy__line {
    display: block;
    margin-bottom: 8px;
    padding: 7px 10px 10px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.05em;
    color: #fff;
    background: #333; }

.mv-txt {
  margin-top: 14px;
  font-size: 20px;
  line-height: 1.71;
  letter-spacing: -0.02em;
  color: #fff; }

.sec-news {
  margin-top: -64px;
  padding: 53px 0 56px;
  background: #fff; }

.sec-cases {
  padding: 95px 0 120px; }

.search-container {
  margin-top: 50px;
  background: #fff; }

.search-tab-container {
  display: flex; }

.search-tab {
  width: 50%;
  cursor: pointer;
  background: #f1f1f1; }
  .search-tab__link {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center; }
    .search-tab__link::before {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%; }
  .search-tab__txt {
    display: flex;
    height: 64px;
    line-height: 1;
    justify-content: center;
    align-items: center; }
  .search-tab__kind {
    font-size: 24px; }

.tab-sale .search-tab__link::before {
  background: #fff url(../images/icon_sale.svg) center center/27px 25px no-repeat; }

.tab-investment .search-tab__link::before {
  background: #fff url(../images/icon_investment.svg) center center/31px 20px no-repeat; }

.js-selected-tab {
  position: relative; }
  .js-selected-tab .search-tab__txt {
    color: #fff; }
  .js-selected-tab::before {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-width: 9px 10px 0 10px;
    border-style: solid;
    border-color: #0068aa transparent transparent transparent; }

.tab-sale.js-selected-tab {
  background: #0068aa; }
  .tab-sale.js-selected-tab::before {
    border-color: #0068aa transparent transparent transparent; }

.tab-investment.js-selected-tab {
  background: #ffa000; }
  .tab-investment.js-selected-tab::before {
    border-color: #ffa000 transparent transparent transparent; }

.search-input-container {
  padding: 32px 40px; }

.js-selected-tab-sale .investment-item {
  display: none; }

.js-selected-tab-investment .sale-item {
  display: none; }

.search-keyword,
.search-country,
.search-industry,
.search-sale-amount {
  display: flex;
  margin-top: 24px;
  align-items: center; }

.search-form__lbl {
  width: 150px;
  flex-grow: 0; }

.search-form .search-placeholder {
  height: 30px;
  cursor: pointer;
  color: #dfdfdf; }

.search-form__input-box, .search-form__selecteditem-box {
  width: 100%;
  padding: 4px 20px 4px 10px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  flex-grow: 1; }

.search-form__selecteditem-box {
  position: relative; }
  .search-form__selecteditem-box::before, .search-form__selecteditem-box::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 24px;
    display: block;
    width: 16px;
    height: 2px;
    transform: translateY(-50%);
    background: #333; }
  .search-form__selecteditem-box::after {
    transform: translateY(-50%) rotate(90deg); }

.search-form__enable-items {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: -1px;
  display: none;
  overflow: auto;
  width: calc(100% + 2px);
  height: 10em;
  line-height: 2;
  transform: translateY(100%);
  border: 1px solid #dfdfdf;
  background: #fff; }

.search-form__enable-row {
  padding: 0 10px;
  cursor: pointer; }
  .search-form__enable-row:hover {
    color: #fff;
    background: #25925688; }

.search-form__hide-select {
  position: absolute;
  top: 0;
  right: 0px;
  opacity: 0; }

.search-form__btns {
  display: flex;
  width: 80%;
  margin: 48px auto 0;
  justify-content: center;
  align-items: center; }

.search-form__remove-btn {
  width: 296px;
  height: 48px;
  margin-right: 16px;
  cursor: pointer; }
  .search-form__remove-btn a {
    display: flex;
    width: 100%;
    height: 100%;
    color: #259256;
    border: solid 2px #259256;
    background: #fff;
    justify-content: center;
    align-items: center; }

.search-form__submit-btn {
  width: 296px;
  height: 48px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  color: #fefefe;
  border: none;
  background: #259256;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.country-modal-bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25); }

.country-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 960px;
  height: 80%;
  max-height: 828px;
  padding: 32px 41px 0;
  transform: translate(-50%, -50%);
  background: #fff; }
  .country-modal__close-btn {
    content: "";
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: url(../images/icon_close.svg) center center/33px 33px no-repeat; }
  .country-modal__container {
    overflow: auto;
    height: calc(100% - 160px);
    margin-top: 12px; }

.check-region {
  padding: 6px 27px;
  background-color: #e9f4ee; }

.check-country {
  display: flex;
  margin-bottom: 10px;
  padding: 6px 27px;
  flex-wrap: wrap; }

.check-country li {
  position: relative;
  width: 25%;
  margin-bottom: 5px; }

input[type=checkbox] {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type=checkbox] + label {
  position: relative;
  padding-left: 30px; }
  input[type=checkbox] + label::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    border: 1px solid #dfdfdf;
    background: #fff; }
  input[type=checkbox] + label::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    opacity: 0;
    background: #259256 url(../images/icon_check.svg) center center/14px 10px no-repeat; }

input[type=checkbox]:checked + label::after {
  opacity: 1; }

.country-btns {
  display: flex;
  width: 70%;
  margin: 30px auto 0;
  justify-content: space-between; }
  .country-btns .clear-btn {
    width: 296px;
    height: 48px;
    cursor: pointer; }
    .country-btns .clear-btn a {
      display: flex;
      width: 100%;
      height: 100%;
      color: #259256;
      border: solid 2px #259256;
      background: #fff;
      justify-content: center;
      align-items: center; }
  .country-btns .green-btn {
    margin: 0; }

.js-selected-list {
  color: #fff;
  background: #259256; }

.js-selected-item {
  display: inline-block;
  height: 30px;
  margin: 3px;
  padding: 0 5px;
  vertical-align: middle;
  border-radius: 5px;
  background: #dfdfdf; }
  .js-selected-item .js-remove-item {
    position: relative;
    top: -2px;
    display: inline-block;
    width: 20px;
    height: 100%;
    font-size: 16px;
    cursor: pointer;
    vertical-align: middle;
    background: transparent; }
    .js-selected-item .js-remove-item::before, .js-selected-item .js-remove-item::after {
      content: "";
      position: absolute;
      position: absolute;
      top: 50%;
      display: block;
      width: 70%;
      height: 1px;
      transform: rotate(45deg);
      background: #333; }
    .js-selected-item .js-remove-item::after {
      transform: rotate(-45deg); }

.js-fixed {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%; }

.result {
  margin-top: 48px; }

.result-list {
  margin-bottom: 56px; }

.result-item-container {
  position: relative;
  overflow: hidden;
  margin-top: 42px;
  border-top: 4px solid #0068aa;
  background: #fff; }

.result-item {
  padding: 32px 32px 36px;
  border: 1px solid #dfdfdf; }
  .result-item::after {
    background: #0068aa; }
  .result-item__no--category {
    display: inline-block;
    margin-right: 8px;
    padding: 3px 12px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: #0068aa;
    border: 2px solid #0068aa;
    border-radius: 20px; }
  .result-item__name {
    margin-top: 4px;
    font-size: 24px;
    font-weight: bold;
    color: #333; }
  .result-item__info {
    display: flex;
    margin-top: 13px; }
    .result-item__info--item {
      width: 32%;
      margin-right: 2%; }
      .result-item__info--item:last-child {
        margin-right: 0; }
    .result-item__info--box {
      width: 100%;
      border: 1px solid #e5f0f6; }
    .result-item__info--ttl {
      padding: 9px 0;
      font-size: 16px;
      line-height: 1;
      text-align: center;
      background: #e5f0f6; }
    .result-item__info--value {
      padding: 21px 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      color: #0068aa; }
  .result-item__others {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap; }
    .result-item__others--ttl {
      width: 10%;
      font-size: 16px;
      font-weight: bold;
      line-height: 2; }
    .result-item__others--value {
      width: 90%;
      font-size: 16px;
      line-height: 2; }
  .result-item__contact {
    width: 320px;
    margin-top: 32px; }
  .result-item__note {
    margin-top: 16px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    color: #b7b7b7; }

.new-case::after {
  content: "NEW";
  position: absolute;
  top: 12px;
  left: -2px;
  display: block;
  width: 80px;
  height: 40px;
  padding-top: 21px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  transform: rotate(-45deg);
  transform-origin: left bottom;
  text-align: center;
  color: #fefefe; }

.result-sale {
  border-color: #0068aa; }
  .result-sale::after {
    background: #0068aa; }
  .result-sale .result-item::after {
    background: #0068aa; }
  .result-sale .result-item__no--category {
    color: #0068aa;
    border-color: #0068aa; }
  .result-sale .result-item__info--box {
    border: 1px solid #e5f0f6; }
  .result-sale .result-item__info--ttl {
    background: #e5f0f6; }
  .result-sale .result-item__info--value {
    color: #0068aa; }

.result-purchase {
  border-color: #e34b37; }
  .result-purchase::after {
    background: #e34b37; }
  .result-purchase .result-item::after {
    background: #e34b37; }
  .result-purchase .result-item__no--category {
    color: #e34b37;
    border-color: #e34b37; }
  .result-purchase .result-item__info--box {
    border: 1px solid #fcedeb; }
  .result-purchase .result-item__info--ttl {
    background: #fcedeb; }
  .result-purchase .result-item__info--value {
    color: #e34b37; }
  .result-purchase .result-item__others--ttl {
    width: 19%; }
  .result-purchase .result-item__others--value {
    width: 81%; }

.result-investment {
  border-color: #ffa000; }
  .result-investment::after {
    background: #ffa000; }
  .result-investment .result-item::after {
    background: #ffa000; }
  .result-investment .result-item__no--category {
    color: #ffa000;
    border-color: #ffa000; }
  .result-investment .result-item__info--box {
    border: 1px solid #fff5e5; }
  .result-investment .result-item__info--ttl {
    background: #fff5e5; }
  .result-investment .result-item__info--value {
    color: #ffa000; }

.all-btn {
  margin-top: 80px; }

.sec-news-list {
  padding: 77px 0 120px; }
  .sec-news-list .news-list {
    margin: 38px 0 56px; }

.news-container {
  width: 800px;
  margin: 0 auto;
  padding: 77px 0 120px; }

.news-article__date {
  font-size: 15px;
  line-height: 1;
  color: #bfbfbf; }

.news-article__ttl {
  margin-top: 2px;
  font-size: 24px;
  font-weight: 500; }

.news-article__pic {
  width: 100%;
  margin-top: 32px; }

.news-article__img {
  display: block;
  max-width: 100%;
  margin: 0 auto; }

.news-article__txt {
  margin-top: 30px;
  line-height: 2; }
  .news-article__txt--pdf {
    text-decoration: underline;
    color: #259256; }

.news-article__txt--pdf[href$=".pdf"]::after {
  content: "";
  position: relative;
  bottom: -2px;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 3px;
  background: url(../images/icon_pdf.svg) center center/contain no-repeat; }

.news-page-btns {
  display: flex;
  width: 100%;
  margin-top: 74px;
  justify-content: space-between;
  align-items: center; }

.pre-news-page__link,
.next-news-page__link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #259256; }

.pre-news-page__link::before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  margin-right: 15px;
  margin-left: 3px;
  transform: rotate(45deg);
  border-bottom: 2px solid #259256;
  border-left: 2px solid #259256; }

.next-news-page__link::after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  margin-right: 3px;
  margin-left: 15px;
  transform: rotate(-135deg);
  border-bottom: 2px solid #259256;
  border-left: 2px solid #259256; }

p.pre-news-page__link,
p.next-news-page__link {
  color: #bfbfbf; }

p.pre-news-page__link::before,
p.next-news-page__link::after {
  border-bottom: 2px solid #bfbfbf;
  border-left: 2px solid #bfbfbf; }
