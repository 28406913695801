@charset "utf-8";

// ---------------------------
// お知らせ一覧ページ
// ---------------------------
.sec-news-list {
  padding: 77px 0 120px;

  .news-list {
    margin: 38px 0 56px;
  }
}
